
.App {
  text-align: center;
  background-color: #282c34;
  background-image:url("./profile.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.App-logo {
  pointer-events: none;
}



a {
  color:white;
  text-decoration: none;
}
a:hover {
  color:cornflowerblue;
  text-decoration: none;
}


.App-container {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:#f0ead6;

}

#scroll-dialog-description{
  white-space: pre-line;
}





.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotateZ(0deg);
    transform: rotateY(0deg);
  }
  to {
    transform: rotateZ(-360deg);
    transform: rotateY(360deg);
  }
}
